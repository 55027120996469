<template>
  <div>
    <component
      :is="LMarker"
      v-for="(marker, index) in markers"
      :key="index"
      :visible="true"
      :draggable="false"
      :lat-lng="{lat: marker.lat, lng: marker.lon}"
      @click="markerClick(marker)"
    >
      <component :is="Icon" v-if="marker.selected && marker.status === 'approved'">
        <b-img
          :src="require('@/assets/images/locations/accept.png')"
          height="25"
        />
      </component>
      <component :is="Icon" v-if="marker.selected && marker.status === 'running'">
        <b-img
          :src="require('@/assets/images/locations/running.png')"
          height="25"
        />
      </component>
      <component :is="LTooltip" :content="marker.address" />
    </component>
  </div>
</template>

<script>


export default {
  name: 'markersMap',
  props: {
    markers: {
      type: Array,
    }
  },
  data() {
    return {
      LMarker: null,
      LTooltip: null,
      Icon: null
    }
  },
  methods: {
    markerClick(marker) {
      this.$emit('marker_click', marker);
    }
  },
  async mounted() {
    const vue2_leaflet = (await import('vue2-leaflet'));
    const leaflet = (await import('leaflet'));

    this.Icon = leaflet.Icon;
    this.LMarker = vue2_leaflet.LMarker;
    this.LTooltip = vue2_leaflet.LTooltip;

    delete this.Icon.Default.prototype._getIconUrl
    this.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });
  },
}
</script>

<style>

</style>